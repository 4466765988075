<template>
  <div class="content-box">
    <div class="content-left">
      <div class="content-bar">
        <el-button type="text" icon="el-icon-arrow-left" @click="onBackClick">返回课程</el-button>
        <span class="title" v-if="activeLesson">{{ activeLesson.ItemName }}</span>
      </div>
      <div class="content-video">
        <video-player v-show="playing" ref="video"></video-player>
        <div v-if="!playing" class="payment-box">
          <span class="text">购买后可以解锁全部课程哦~</span>
          <el-button type="primary" class="button" @click="onBackClick">立即购买</el-button>
        </div>
      </div>
    </div>
    <div class="content-right">
      <el-tabs v-model="activeTab" type="border-card" class="tabs">
        <el-tab-pane label="课程课表" name="1">
          <course-lesson-list v-if="courseInfo" :courseType="courseInfo.CourseType" :active="activeLesson" :unlocked="unlocked" :list="courseInfo.CourseType == 0 ? lessonList : courseGroupList" @play-click="onPlayClick" @unlock-click="onUnlockClick"></course-lesson-list>
        </el-tab-pane>
        <el-tab-pane label="师资介绍" name="2">
          <div v-if="courseInfo" class="teacher-text">{{ courseInfo.CourseTeacherDescribe }}</div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/PC/VideoPlayer";
import CourseLessonList from "@/components/PC/CourseLessonList";
import Config from "@/api/video";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    VideoPlayer,
    CourseLessonList,
  },
  data() {
    return {
      unlocked: false,
      courseGuid: null,
      courseInfo: null,
      activeTab: "1",
      activeLesson: null,
      lessonList: [],
      courseGroupList: [],
    };
  },
  computed: {
    playing() {
      if (this.unlocked || (this.activeLesson && this.activeLesson.LessonFreeVideo)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    //初始化视频
    initVideo() {
      this.$refs.video.init(null, null);
    },
    //播放视频
    playVideo() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var url = `${Config.course.lessonVideo}?CourseGuid=${this.courseGuid}&LessonGuid=${this.activeLesson.LessonGuid}&Token=${this.$store.state.token}`;
      this.$refs.video.replay(url);
    },
    //获取路由参数
    getRoutParams() {
      this.courseGuid = this.$route.query.courseGuid;
      if (this.$route.query.token) {
        this.$store.commit("setToken", this.$route.query.token);
        this.$router.replace({
          name: "Video",
          query: {
            courseGuid: this.$route.query.courseGuid
          }
        });
      }
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(false);
      }
    },
    //获取是否购买课程
    getCoursePurchase() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.purchased, model)
        .then((res) => {
          this.unlocked = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取课程信息
    getCourseInfo() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.courseInfo, model)
        .then((res) => {
          this.courseInfo = res.data.Data;
          if (this.courseInfo.CourseType == 0) {
            this.getCourseLessonList();
          } else if (this.courseInfo.CourseType == 1) {
            this.getCourseGroupList();
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取课程组合列表
    getCourseGroupList() {
      var model = {
        CourseGuid: this.courseGuid
      };
      this.$axios
        .post(Config.course.groupList, model)
        .then((res) => {
          let data = res.data.Data;
          this.courseGroupList = data.List;
          if (this.courseGroupList.length > 0 && this.courseGroupList[0].CourseLessonList.length > 0 && this.courseGroupList[0].CourseLessonList[0].LessonFreeVideo) {
            this.activeLesson = this.courseGroupList[0].CourseLessonList[0];
            this.playVideo();
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取课时列表
    getCourseLessonList() {
      var model = {
        CourseGuid: this.courseGuid,
        PageIndex: 1,
        PageSize: 100,
      };
      this.$axios
        .post(Config.course.lessonList, model)
        .then((res) => {
          let data = res.data.Data;
          this.lessonList = data.List;
          if (this.lessonList.length > 0) {
            this.activeLesson = this.lessonList[0];
            this.playVideo();
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //点击返回
    onBackClick() {
      window.location.href = Manager.goCoursePreviewUrl(this.$store.state.proxyCode, this.courseGuid);
    },
    //点击播放
    onPlayClick(item) {
      if (!this.$store.getters.isLogin) {
        this.$message.warning('请先登录。');
      }
      this.activeLesson = item;
      this.playVideo();
    },
    //点击解锁
    onUnlockClick(item) {
      // this.activeLesson = item;
      window.location.href = Manager.goCoursePreviewUrl(this.$store.state.proxyCode, this.courseGuid);
    },
  },
  mounted() {
    console.log(window.location.hostname, '域名');
    //通过判断域名、是否已经登录、是否传递token，来判断该链接是否是分享访问。
    if (window.location.hostname == 'v.handebook.com' && !this.$store.getters.isLogin && !this.$route.query.token) {
      this.$alert('为了方便同步登录信息，请从网站跳转观看，该域名暂不支持分享链接方式访问。', '提示');
      return;
    }

    this.initVideo();
    this.getRoutParams();
    this.getCoursePurchase();
    this.getCourseInfo();
  },
};
</script>

<style scoped lang="less">
.content-box {
  width: 100%;
  height: 100%;
  display: flex;
}

.content-left {
  flex: 1;
  display: flex;
  flex-direction: column;

  .content-bar {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-shadow: inset 1px 1px 2px rgba(103, 103, 103, 0.3);

    .title {
      font-size: 16px;
      font-weight: bold;
      margin: auto;
    }
  }

  .content-video {
    flex: 1;
    background: rgba(22, 22, 22);

    .payment-box {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text {
        color: white;
        font-size: 16px;
      }

      .button {
        margin: 20px;
      }
    }
  }
}

.content-right {
  width: 350px;

  .tabs {
    height: calc(100% - 2px);
  }

  .teacher-text {
    color: #555;
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 10px;
  }
}

::v-deep .el-tabs--border-card>.el-tabs__content {
  padding: 0px !important;
  height: calc(100% - 40px) !important;
  overflow-y: auto !important;
}
</style>