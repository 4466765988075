function host() {
    if ((process.env.NODE_ENV == "development")) {
        //开发环境
        return "http://v.handebook.com:81";
    } else {
        //生产地址
        if (window.location.protocol == 'https:') {
            return "https://v.handebook.com";
        }
        else {
            return "http://v.handebook.com";
        }
    }
}

export default {
    course: {
        lessonVideo: `${host()}/api/web/course/lesson/video`,
        coverImage: `${host()}/api/web/course/cover/image`,
        introImage: `${host()}/api/web/course/intro/image`,
        purchased: `${host()}/api/web/course/purchased`,
        courseInfo: `${host()}/api/web/course/info`,
        groupList: `${host()}/api/web/course/group/list`,
        lessonList: `${host()}/api/web/course/lesson/list`,
    },
}