<template>
    <div>
        <van-sticky>
            <title-bar2 :title="courseInfo && courseInfo.CourseName" />
        </van-sticky>
        <div class="content-box">
            <div class="content-video-box">
                <video-player ref="video" class="content-video"></video-player>
            </div>
            <div class="content-tabs-box">
                <van-tabs v-model="activeTab">
                    <van-tab title="课程课表" name="1">
                        <course-lesson-list v-if="courseInfo" :courseType="courseInfo.CourseType" :active="activeLesson" :unlocked="unlocked" :list="courseInfo.CourseType == 0 ? lessonList : courseGroupList" @play-click="onPlayClick" @unlock-click="onUnlockClick"></course-lesson-list>
                    </van-tab>
                    <van-tab title="师资介绍" name="2">
                        <div v-if="courseInfo" class="teacher-text">{{ courseInfo.CourseTeacherDescribe }}</div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import VideoPlayer from "@/components/MT/VideoPlayer";
import CourseLessonList from "@/components/MT/CourseLessonList";
import Config from "@/api/video";
import Manager from "@/manager";
export default {
    name: "PCModule",
    components: {
        TitleBar2,
        VideoPlayer,
        CourseLessonList,
    },
    props: {
    },
    data() {
        return {
            unlocked: null,
            courseGuid: null,
            courseInfo: null,
            activeTab: '1',
            activeLesson: null,
            lessonList: [],
            courseGroupList: [],
        };
    },
    computed: {
        courseIntroImage() {
            if (this.courseGuid) {
                return `${Config.course.coverImage}?CourseGuid=${this.courseGuid}`;
            }
        },
    },
    methods: {
        //初始化视频
        initVideo() {
            this.$refs.video.init(null, null);
        },
        //播放视频
        playVideo() {
            if (!this.$store.getters.isLogin) {
                return;
            }
            var url = `${Config.course.lessonVideo}?CourseGuid=${this.courseGuid}&LessonGuid=${this.activeLesson.LessonGuid}&Token=${this.$store.state.token}`;
            console.log('播放地址', url);
            this.$refs.video.replay(url);
        },
        //获取路由参数
        getRoutParams() {
            this.courseGuid = this.$route.query.courseGuid;
            if (this.$route.query.token) {
                this.$store.commit("setToken", this.$route.query.token);
                this.$router.replace({
                    name: "Video",
                    query: {
                        courseGuid: this.$route.query.courseGuid
                    }
                });
            }
            if (!this.$store.getters.isLogin) {
                this.$loginDialog.show(false);
            }
        },
        //获取是否购买课程
        getCoursePurchase() {
            if (!this.$store.getters.isLogin) {
                return;
            }
            var model = {
                CourseGuid: this.courseGuid
            };
            this.$axios
                .post(Config.course.purchased, model)
                .then((res) => {
                    this.unlocked = res.data.Data.IsPurchased;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取课程信息
        getCourseInfo() {
            var model = {
                CourseGuid: this.courseGuid
            };
            this.$axios
                .post(Config.course.courseInfo, model)
                .then((res) => {
                    this.courseInfo = res.data.Data;
                    if (this.courseInfo.CourseType == 0) {
                        this.getCourseLessonList();
                    } else if (this.courseInfo.CourseType == 1) {
                        this.getCourseGroupList();
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取课程组合列表
        getCourseGroupList() {
            var model = {
                CourseGuid: this.courseGuid
            };
            this.$axios
                .post(Config.course.groupList, model)
                .then((res) => {
                    this.courseGroupList = res.data.Data.List;
                    if (this.courseGroupList.length > 0 && this.courseGroupList[0].CourseLessonList.length > 0 && this.courseGroupList[0].CourseLessonList[0].LessonFreeVideo) {
                        this.activeLesson = this.courseGroupList[0].CourseLessonList[0];
                        this.playVideo();
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取课时列表
        getCourseLessonList() {
            var model = {
                CourseGuid: this.courseGuid
            };
            this.$axios
                .post(Config.course.lessonList, model)
                .then((res) => {
                    this.lessonList = res.data.Data.List;
                    if (this.lessonList.length > 0 && this.lessonList[0].LessonFreeVideo) {
                        this.activeLesson = this.lessonList[0];
                        this.playVideo();
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击播放
        onPlayClick(item) {
            if (!this.$store.getters.isLogin) {
                this.$dialog({ title: "提示", message: "请先登录。" });
            }
            this.activeLesson = item;
            this.playVideo();
        },
        //点击解锁
        onUnlockClick(item) {
            window.location.href = Manager.goCoursePreviewUrl(this.$store.state.proxyCode, this.courseGuid);
        },
        //课时加载事件
        onDataLoad() {
            this.getCourseLessonList();
        },
    },
    mounted() {
        console.log(window.location.hostname, '域名');
        //通过判断域名、是否已经登录、是否传递token，来判断该链接是否是分享访问。
        if (window.location.hostname == 'v.handebook.com' && !this.$store.getters.isLogin && !this.$route.query.token) {
            this.$alert('为了方便同步登录信息，请从网站跳转观看，该域名暂不支持分享链接方式访问。', '提示');
            return;
        }

        this.initVideo();
        this.getRoutParams();
        this.getCoursePurchase();
        this.getCourseInfo();
        this.getCourseLessonList();
    }
};
</script>
<style lang="less" scoped>
.content-box {
    padding: 0 10px;
}

.content-video-box {
    display: flex;
    flex-direction: column;
    border: 1px solid #dcdfe6;

    .content-title {
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 14px;
        background-color: #f5f7fa;
    }

    .content-video {
        flex: 1;
    }
}

.content-tabs-box {
    margin: 20px 0 10px 0;
    background-color: white;

    .free {
        position: absolute;
        top: 0px;
        left: 145px;
        z-index: 100;
        background-color: red;
        color: white;
        font-size: 10px;
        padding: 1px 8px;
        border-radius: 20px;
    }

    .introl-image {
        width: 100%;
        margin-top: 10px;
    }

    .teacher-text {
        color: #555;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        padding: 10px;
    }
}
</style>